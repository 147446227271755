import React from 'react'
import './Testimonial.sass'

function Testimonial(props) {
    return (
        <div className="testimonial" data-aos="fade-right">
            <div className="quote">
                <h3>"{props.quote}"</h3>
            </div>
            <div className="details">
                <div className="avatar">
                    <img src={props.avatar} alt="Testimonial" />
                </div>
                <div className="info">
                    <p>
                        <span className="name">{props.name}</span>
                        <span className="position">{props.position}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
