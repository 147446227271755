import React from "react";
import { useEffect } from "react";
import "./Portfolio.sass";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css";
import CarouselCell from "../components/CarouselCell";

function Portfolio() {
	useEffect(() => {
		var portfolio = document.querySelector(".portfolio-carousel");
		var portfolioCarousel = new Flickity(portfolio, {
			pageDots: false,
			wrapAround: true,
			autoPlay: 2000,
		});
	}, []);

	return (
		<div className="portfolio">
			<div className="portfolio-carousel">
				<CarouselCell
					title="iLess"
					subtitle="Tarjeta de presentación inteligente"
					details="Desarrollo web | App"
					href="https://iless.mx/"
					image="https://i.ibb.co/StxdSm6/iless.jpg"
					bgcolor="#50517E"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Malva Travel to Mexico"
					subtitle="Tour operadora mexicana"
					details="Branding | Desarollo web"
					href="https://malvatraveltomexico.com.mx"
					image="https://i.ibb.co/pP6QRr3/malva-travel.jpg"
					bgcolor="#701858"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Intelligent Decisions"
					subtitle="Bufete fiscal y contable"
					details="Desarrollo web"
					href="https://idcontadores.com.mx"
					image="https://i.ibb.co/HdfDMTd/intelligent-decisions.jpg"
					bgcolor="#018591"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Peritos México"
					subtitle="Consultoría psicológica forense"
					details="Branding | Desarrollo web"
					href="https://peritosmexico.com.mx/"
					image="https://i.ibb.co/KKSTJMX/peritos-mexico.jpg"
					bgcolor="#004C75"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Adaptaciones móviles"
					subtitle="Servicios de espacios móviles"
					details="Desarrollo web"
					href="https://amoviles.com.mx/"
					image="https://i.ibb.co/dJ0KY0V/adapataciones-moviles.jpg"
					bgcolor="#D06740"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Grupo Guerrero"
					subtitle="Servicios de construcción"
					details="Branding | Desarrollo web"
					href="https://gguerrero.com.mx/"
					image="https://i.ibb.co/sq0JTVW/grupo-guerrero.jpg"
					bgcolor="#25AFE5"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="432 Films"
					subtitle="Productora Audiovisual"
					details="Desarrollo web"
					href="https://www.432films.com/"
					image="https://i.ibb.co/mG9dsKz/432films.jpg"
					bgcolor="#A1997B"
					textcolor="#ffffff"
				/>

				<CarouselCell
					title="Piercing Emporium"
					subtitle="Piercing And Tattoo Studio."
					details="Branding | Desarrollo web"
					href="https://piercingemporium.com/"
					image="https://i.ibb.co/4Ykr7St/piercing-emporium.jpg"
					bgcolor="#661670"
					textcolor="#ffffff"
				/>
			</div>
		</div>
	);
}

export default Portfolio;
