import React from 'react'
import './Contact.sass'
import MainButton from '../components/MainButton'

function Contact() {
    return (
        <div className="contact">
            <div className="container">                
                <h2 data-aos="fade-up">Cuéntame que traes en mente y trabajemos juntos. <span className="hightlight">El café va por mi cuenta.</span></h2>
                <div className="button" data-aos="fade-down">
                    <MainButton title="Escríbeme para agendar" icon="Calendar" href="mailto:info@erikespinosar.com" />
                </div>
            </div>
        </div>
    )
}

export default Contact