import React from 'react'
import './Skills.sass'
import skills from '../assets/skills.png'
import html5 from '../assets/html5.svg'
import css3 from '../assets/css3.svg'
import js from '../assets/js.svg'
import react from '../assets/react.svg'
import php from '../assets/php.svg'
import laravel from '../assets/laravel.svg'
import wordpress from '../assets/wordpress.svg'
import git from '../assets/git.svg'
import figma from '../assets/figma.svg'
import photoshop from '../assets/photoshop.svg'
import illustrator from '../assets/illustrator.svg'
import OtherSkill from '../components/OtherSkill'
import MainButton from '../components/MainButton'

function Skills() {
    return (
        <div className="skills">
            <div className="container">
                <div className="row">
                    <div className="illustration" data-aos="fade-right">
                        <img src={ skills } alt="Súperpoderes" />
                    </div>
                    <div className="cards">
                        <div data-aos="flip-left">
                            <h2>Súperpoderes.</h2>
                            <p>El aprendizaje constante es la base para contar con resultados de alta calidad. Acá algunas de mis habilidades destacadas:</p>
                        </div>
                        <div data-aos="flip-right">
                            <img src={ html5 } alt="HTML5" />
                            <img src={ css3 } alt="CSS3" />
                            <img src={ js } alt="JavaScript" />
                            <img src={ react } alt="React" />
                            <img src={ php }  alt="PHP" />
                            <img src={ laravel } alt="Laravel" />
                            <img src={ wordpress } alt="WordPress" />
                            <img src={ git } alt="GIT" />
                            <img src={ figma } alt="Figma" />
                            <img src={ photoshop } alt="Adobe Photoshop" />
                            <img src={ illustrator } alt="Adobe Illustrator" />
                        </div>
                        <div data-aos="flip-left">
                            <div className="other-skills">
                                <OtherSkill skill="Desarrollo web" icon="Code" />
                                <OtherSkill skill="Diseño UI/UX" icon="Figma" />
                                <OtherSkill skill="Modelado SQL" icon="Database" />
                                <OtherSkill skill="Consultoría en TI" icon="Server" />
                                <OtherSkill skill="Optimización SEO" icon="Search" />
                                <OtherSkill skill="Ciclismo" icon="Disc" />
                                <OtherSkill skill="Ilustración" icon="PenTool" />
                                <OtherSkill skill="Música" icon="Music" />
                                <OtherSkill skill="Meditación" icon="Heart" />
                                <OtherSkill skill="Fotografía" icon="Camera" />
                                <OtherSkill skill="Café" icon="Coffee" />
                            </div>
                            <div className="button">
                                <MainButton title="Ver mas en" href="https://www.linkedin.com/company/eedesarrolladorweb/" icon="Linkedin" target="_blank" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills