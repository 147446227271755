import React from 'react'
import * as Icon from 'react-feather'
import './MainButton.sass'

function MainButton(props) {
    const IconTag = Icon[props.icon]
    
    return (
        <a className="main-button" href={props.href} target={props.target}>
            {props.title}
            <IconTag />
        </a>
    )
}

export default MainButton
