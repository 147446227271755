import React from 'react'
import * as Icon from 'react-feather'
import './OtherSkill.sass'

function OtherSkill(props) {
    const IconTag = Icon[props.icon]

    return (
        <div className="other-skill">
            <IconTag />
            <span>{props.skill}</span>
        </div>
    )
}

export default OtherSkill
