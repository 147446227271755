import React from 'react'
import './Footer.sass'
import footer from '../assets/footer.png'

function Footer() {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="illustration" data-aos="fade-left">
                        <img src={ footer } alt="Galaxy" />
                    </div>
                    <div className="info" data-aos="fade-right">
                        <p>
                            {getCurrentYear()}. erikespinosar.com<br></br>
                            Ciudad de México, México<br></br>
                            <a href="mailto:info@erikespinosar.com"> info@erikespinosar.com</a><br></br>
                            <a href="tel:+525513977547">+52 55 1397 7547</a>
                        </p>
                        <p>
                            Atribuciones:<br></br>
                            <a href="https://dribbble.com/alzea" target="_blank" rel="noreferrer">Alzea Arafat</a><br></br>
                            <a href="https://github.com/feathericons/feather" target="_blank" rel="noreferrer">Feather</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
