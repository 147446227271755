import { useEffect } from 'react'
import './App.sass'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Header from './template/Header'
import Footer from './template/Footer'
import Hero from './sections/Hero'
import Skills from './sections/Skills'
import Portfolio from './sections/Portfolio'
import Testimonials from './sections/Testimonials'
import Contact from './sections/Contact'

function App() {
  useEffect( () => {
    Aos.init( {
      duration: 1000,
      easing: 'ease-in-out-sine'
    })
  }, [])

  return (
    <div>
      <Header />
      <Hero />
      <Skills />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App