import React from "react";
import "./Hero.sass";
import splash from "../assets/splash.png";
import MainButton from "../components/MainButton";

function Hero() {
	return (
		<div className="hero">
			<div className="container">
				<div className="row">
					<div className="info" data-aos="fade-right">
						<h1>Desarrollador Web/Frontend de la Ciudad de México.</h1>
						<p>
							Erik Espinosa, entusiasta de la tecnología y el software. Con más
							de 10 años de experiencia creando múltiples sitios web,
							plataformas de comercio electrónico y aplicaciones especializadas.{" "}
							<strong>Mi pasión es el aprendizaje contínuo.</strong>
						</p>
						<MainButton
							title="Escríbeme un email"
							href="mailto:info@erikespinosar.com"
							icon="Mail"
						/>
					</div>
					<div className="illustration" data-aos="fade-left">
						<img src={splash} alt="Hero" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Hero;
