import React from 'react'
import Testimonial from '../components/Testimonial'
import './Testimonials.sass'
import MainButton from '../components/MainButton'

function Testimonials() {
    return (
        <div className="testimonials">
            <div className="title">
                <h2>Testimoniales</h2>
                <p>Opiniones de personas y empresas con los que he trabajado.</p>
            </div>
            <div className="container">
                <div className="row">
                    <Testimonial quote="Una persona muy profesional, puntual en lo convenido y excelente trabajo." avatar="https://i.ibb.co/KV1P9r8/abdiel-del-toro.jpg" name="Abdiel del Toro" position="Director General de Intelligent Decisions" />

                    <Testimonial quote="Excelente atención, súper recomendable. Entendió totalmente mi idea y quede súper contenta con el resultado." avatar="https://i.ibb.co/GVfj6c5/adriana-rodriguez.jpg" name="Adriana Rodríguez" position="Fundadora de Ady Rod" />

                    <Testimonial quote="Erik es nuestro proveedor desde que iniciamos como empresa en Malva Travel y siempre ha estado atento a nuestras necesidades y soluciona oportunamente nuestros requerimientos. Lo recomiendo mucho." avatar="https://i.ibb.co/VTPZSgg/paulina-roman.jpg" name="Paulina Roman" position="Directora de Malva Travel to Mexico" />

                    <Testimonial quote="Erik es un desarrollador web innovador, atento y responsable, su capacidad para solucionar problemas es notorio, siempre  nutriendo sus proyectos y su conocimiento." avatar="https://i.ibb.co/Byzkp8r/nath-santamaria.jpg" name="Nath Santamaría" position="Fundadora de Umenoki" />

                    <Testimonial quote="Súper profesional y accesible en mis requerimientos, buen seguimiento del proyecto y sobre todo excelente comunicación." avatar="https://i.ibb.co/6m48QVk/mario-martinez.jpg" name="Mario Martínez" position="Fundador de Aretusa Store" />
                </div>
                <div className="button">
                    <MainButton title="Ver mas en" href="https://www.facebook.com/eedesarrolladorweb/reviews" icon="Facebook" target="_blank" />
                </div>
            </div>
        </div>
    )
}

export default Testimonials
