import React from 'react'
import './CarouselCell.sass'
import * as Icon from 'react-feather'

function CarouselCell(props) {
    var cellStyles = {
        backgroundImage: 'url(' + props.image + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }
    var contentStyles = {
        backgroundColor: props.bgcolor,
        color: props.textcolor,
        opacity: 0.8
    }
    
    return (
        <div className="carousel-cell" style={ cellStyles }>
            <div className="content" style={ contentStyles }>
                <div className="title">{props.title}</div>
                <div className="subtitle">{props.subtitle}</div>
                <div className="details">{props.details}</div>
                <a href={props.href} target="_blank" rel="noreferrer">
                    <Icon.Link />
                </a>
            </div>
        </div>
    )
}


export default CarouselCell
