import React from "react";
import "./Header.sass";
import * as Icon from "react-feather";
import logo from "../assets/logo.svg";

function Header() {
	return (
		<div className="header">
			<div className="container">
				<div className="row">
					<div className="logo">
						<a href="/">
							<img src={logo} alt="erikespinosar.com" />
						</a>
					</div>
					<div className="social">
						<a
							href="https://www.linkedin.com/company/eedesarrolladorweb"
							target="_blank"
							rel="noreferrer"
						>
							<Icon.Linkedin />
						</a>
						<a
							href="https://github.com/ErikEspinosa/"
							target="_blank"
							rel="noreferrer"
						>
							<Icon.GitHub />
						</a>
						<a href="mailto:info@erikespinosar.com">
							<Icon.Mail />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
